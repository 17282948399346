import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import _ from 'lodash';
import FeaturePost from '../../components/feature-post/feature-post';
import {
    SidebarWrapper,
    SidebarWidget,
    WidgetTitle,
    TagItem, SidebarWidgetAd
} from './style';
import { TagNode} from "../../../../interfaces/tag";
import Banner from "../../../../shared/Banner";
type SidebarProps = {};

const Sidebar: React.FunctionComponent<SidebarProps> = () => {
  const Data = useStaticQuery(graphql`
   query LimitedSidebarQuery {
  allWordpressPost(limit: 5,sort:{order:DESC,fields:wordpress_id}) {
    edges {
      node {
      id
      categories{
      slug
      name
      }
       tags{
      slug
      name
      }
        ...PostInfos        
      }
    }
  }
  
  allWordpressTag(limit: 12,sort: {order: DESC, fields: count}) {
    edges {
      node {
        name
        slug
        id
        count
      }
    }
  }
  allWordpressCategory(limit: 10) {
    edges {
      node {
        slug
        name
        id
        count
      }
    }
  }
}

  `);

  const Posts = Data.allWordpressPost.edges;
  const Tags = Data.allWordpressTag.edges;

  return (
    <SidebarWrapper>

      <SidebarWidgetAd>
          <Banner style={{width:'100%',height:350,backgroundColor:'#fff'}} responsive={'true'} slot="4409520027" />
      </SidebarWidgetAd>


      <SidebarWidget>
        <WidgetTitle>Latest Post</WidgetTitle>
        {Posts.map(({ node }: any) => {
          const title = node.title || node.slug;
          // Random Placeholder Color
          const placeholderColors = [
            '#55efc4',
            '#81ecec',
            '#74b9ff',
            '#a29bfe',
            '#ffeaa7',
            '#fab1a0',
            '#e17055',
            '#0984e3',
            '#badc58',
            '#c7ecee',
          ];
          const setColor =
            placeholderColors[
              Math.floor(Math.random() * placeholderColors.length)
            ];

          return (
            <FeaturePost
              key={node.slug}
              title={title}
              image={
               null
              }
              url={node.slug}
              tags={node.tags}
              placeholderBG={setColor}
            />
          );
        })}
      </SidebarWidget>

      <SidebarWidget>
        <WidgetTitle>Tags</WidgetTitle>
        {Tags.map(({node}: TagNode) => (
          <TagItem key={node.id}>
            <span>#</span>
            <Link to={`/tags/${_.kebabCase(node.slug)}/`}>
              {node.name} <span>({node.count})</span>
            </Link>
          </TagItem>
        ))}
      </SidebarWidget>


    </SidebarWrapper>
  );
};

export default Sidebar;
