import React from 'react';
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import urljoin from 'url-join';

import Layout from '../components/layout';
import SEO from '../components/seo';
import PostCard from '../components/post-card/post-card';
import PostDetails from '../components/post-details/post-details';
import Sidebar from '../containers/sidebar';
import {
    FacebookShareButton,
    TwitterShareButton,
    PinterestShareButton,
    RedditShareButton,
} from 'react-share';
import {
    IoLogoFacebook,
    IoLogoTwitter,
    IoLogoPinterest,
    IoLogoReddit,
} from 'react-icons/io';
import {
    BlogPostDetailsWrapper,
    RelatedPostWrapper,
    RelatedPostItems,
    RelatedPostTitle,
    RelatedPostItem,
    BlogPostFooter,
    PostShare,
    PostTags,
    BlogDetailsContent,
} from './templates.style';
import {Tag} from "../../../interfaces/tag";

const BlogPostTemplate = (props: any) => {
    console.log(props);
    const post = props.data.wordpressPost;
    const { edges }  = props.data.allWordpressPost;
    const title = post.title;
    const slug = post.slug;
    const siteUrl = "";
    const shareUrl = urljoin(siteUrl, slug);



    const ldJson = {
           };


    return (
        <Layout>
            <SEO
                title={post.title}
                description={(post.excerpt!==null && post.excerpt!=="")?post.excerpt:null}
                ldJson={ldJson}
            />
            <BlogPostDetailsWrapper>
                <BlogDetailsContent>
                    <PostDetails
                        imagePosition={'top'}
                        title={post.title}
                        date={post.date}
                        preview={
                            post.featured_media == null
                                ? null
                                : post.featured_media.localFile.childImageSharp.fluid
                        }
                        description={post.content}
                    />

                    <BlogPostFooter>
                        {post.tags == null ? null : (
                            <PostTags className="post_tags">
                                {post.tags.map((tag: Tag, index: number) => (
                                    <Link key={index} to={`/tags/${_.kebabCase(tag.slug)}/`}>
                                        {`#${tag.name}`}
                                    </Link>
                                ))}
                            </PostTags>
                        )}
                        <PostShare>
                            <span>Share This:</span>
                            <FacebookShareButton url={shareUrl} quote={post.excerpt}>
                                <IoLogoFacebook />
                            </FacebookShareButton>
                            <TwitterShareButton url={shareUrl} title={title}>
                                <IoLogoTwitter />
                            </TwitterShareButton>
                            <PinterestShareButton
                                url={shareUrl}
                                media={urljoin(siteUrl, post.featured_media.localFile.publicURL)}
                            >
                                <IoLogoPinterest />
                            </PinterestShareButton>
                            <RedditShareButton
                                url={shareUrl}
                                title={`${post.title}`}
                            >
                                <IoLogoReddit />
                            </RedditShareButton>
                        </PostShare>
                    </BlogPostFooter>


                </BlogDetailsContent>
                <Sidebar />
            </BlogPostDetailsWrapper>

            {edges.length !== 0 && (
                <RelatedPostWrapper>
                    <RelatedPostTitle>Related Posts</RelatedPostTitle>
                    <RelatedPostItems>
                        {edges.map(({ node }: any) => {
                            // Random Placeholder Color
                            const placeholderColors = [
                                '#55efc4',
                                '#81ecec',
                                '#74b9ff',
                                '#a29bfe',
                                '#ffeaa7',
                                '#fab1a0',
                                '#e17055',
                                '#0984e3',
                                '#badc58',
                                '#c7ecee',
                            ];
                            const setColor =
                                placeholderColors[
                                    Math.floor(Math.random() * placeholderColors.length)
                                    ];
                            return (
                                <RelatedPostItem key={node.slug}>
                                    <PostCard
                                        title={node.title || node.slug}
                                        url={node.slug}
                                        image={
                                            node.featured_media == null
                                                ? null
                                                : node.featured_media.localFile.childImageSharp.fluid
                                        }
                                        tags={node.tags}
                                        placeholderBG={setColor}
                                    />
                                </RelatedPostItem>
                            );
                        })}
                    </RelatedPostItems>
                </RelatedPostWrapper>
            )}
        </Layout>
    );
};

export default BlogPostTemplate;
export const postQuery = graphql`
 
  query BlogPostByID($id: String!,$categories: [String]!) {
  
  allWordpressPost(limit:6,filter: {categories: {elemMatch: {slug: {in: $categories}}}}) {
    edges {
      node {
        slug
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featured_media {
       
          localFile {
            publicURL
            relativePath
            childImageSharp {
              fluid(maxWidth: 800, maxHeight: 420, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
      }
      }
    }
  }
  
    wordpressPost(id: { eq: $id }) {
      id
      title
      slug
      content
      date(formatString: "MMMM DD, YYYY")
     categories{
        slug
        name
     }
      featured_media {
          localFile {
            relativePath
            publicURL
            childImageSharp {
              fluid(maxWidth: 800, maxHeight: 420, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
      }
      
    }
  }
`
